import '../styles/reset.css';
import	'../../node_modules/@splidejs/splide/dist/css/splide.min.css';
import	'../../node_modules/glightbox/dist/css/glightbox.css';
import '../styles/micromodal.css';
import '../styles/index.scss';

import MicroModal from 'micromodal';
import Splide from '@splidejs/splide';
import GLightbox from 'glightbox';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}



document.querySelectorAll(".micromodal-slide").forEach(function(modal){
	var id = "#" + modal.getAttribute("id");
	GLightbox({
		selector: id + " .modal-imgs a"
	});

	GLightbox({
		selector: id + " .splide a"
	});
});



MicroModal.init({
	awaitCloseAnimation: true,
	onShow: function(modal){
		modal.querySelector(".modal__container").scroll(0, 0);
	}
});

new Splide( '#splide1', {
	perPage: 3,
	gap: 30,
	breakpoints: {
		'688': {
			perPage: 2,
		},
		'480': {
			perPage: 1,
		},
	}
}).mount();

new Splide( '#splide2', {
	perPage: 3,
	gap: 30,
	breakpoints: {
		'688': {
			perPage: 2,
		},
		'480': {
			perPage: 1,
		},
	}
}).mount();



document.querySelectorAll(".micromodal-slide .splide").forEach(function(slider){
	var id = slider.getAttribute("id");
	new Splide( '#' + id, {
		perPage: 1
	}).mount();
});







document.querySelector(".openmenu").addEventListener("click", function(){
	document.querySelector(".first").classList.toggle("active");
});



document.querySelectorAll('[href^="#"]').forEach(function(a){
	a.addEventListener("click", function(e){
		e.preventDefault();
		var href = a.getAttribute("href");
		var element = document.querySelector(href);
		window.scrollTo({ 
			top: element.offsetTop,
			behavior: 'smooth'
		});
	});
});



const loadScript = (source, beforeEl, async = true, defer = true) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    const prior = beforeEl || document.getElementsByTagName('script')[0];

    script.async = async;
    script.defer = defer;

    function onloadHander(_, isAbort) {
      if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
        script.onload = null;
        script.onreadystatechange = null;
        script = undefined;

        if (isAbort) { reject(); } else { resolve(); }
      }
    }

    script.onload = onloadHander;
    script.onreadystatechange = onloadHander;

    script.src = source;
    prior.parentNode.insertBefore(script, prior);
  });
}






function GetCenterFromDegrees(data)
{       
    if (!(data.length > 0)){
        return false;
    } 
    var num_coords = data.length;

    var X = 0.0;
    var Y = 0.0;
    var Z = 0.0;

    for(let i = 0; i < data.length; i++){
        var lat = data[i][0] * Math.PI / 180;
        var lon = data[i][1] * Math.PI / 180;

        var a = Math.cos(lat) * Math.cos(lon);
        var b = Math.cos(lat) * Math.sin(lon);
        var c = Math.sin(lat);
        X += a;
        Y += b;
        Z += c;
    }

    X /= num_coords;
    Y /= num_coords;
    Z /= num_coords;



    var lon = Math.atan2(Y, X);
    var hyp = Math.sqrt(X * X + Y * Y);
    var lat = Math.atan2(Z, hyp);

    var newX = (lat * 180 / Math.PI);
    var newY = (lon * 180 / Math.PI);

    return new Array(newX, newY);
}


document.querySelectorAll(".map-buttons div").forEach(function(div){
	div.addEventListener("click", function(){
		document.querySelectorAll(".map-buttons div").forEach(function(div){
			div.classList.remove("active");
		});
		div.classList.add("active");

		document.querySelectorAll(".map").forEach(function(map){
			map.classList.remove("active");
		});

		var id = div.getAttribute("data-id");
		document.querySelector("#" + id).classList.add("active");
	});
});
	

var googleApiKey = document.querySelector("body").getAttribute("data-google-api");
loadScript("https://polyfill.io/v3/polyfill.min.js?features=default").then(() => {
    loadScript("https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js").then(() => {
    	loadScript("https://maps.googleapis.com/maps/api/js?key="+googleApiKey+"&libraries=&v=weekly").then(() => {

			var coords = [];
			window.locations1.forEach(function(location){
				coords.push([location.lat, location.lng]);
			});

			var arr = GetCenterFromDegrees(coords);

    		const map = new google.maps.Map(document.getElementById("map1"), {
				zoom: 11,
				center: { lat: arr[0], lng: arr[1] },
				scaleControl: false,
				streetViewControl: false,
				mapTypeControl: false,
				fullscreenControl: false,
				styles: [
						    {
						        "featureType": "all",
						        "elementType": "labels.text.fill",
						        "stylers": [
						            {
						                "saturation": 36
						            },
						            {
						                "color": "#333333"
						            },
						            {
						                "lightness": 40
						            }
						        ]
						    },
						    {
						        "featureType": "all",
						        "elementType": "labels.text.stroke",
						        "stylers": [
						            {
						                "visibility": "on"
						            },
						            {
						                "color": "#ffffff"
						            },
						            {
						                "lightness": 16
						            }
						        ]
						    },
						    {
						        "featureType": "all",
						        "elementType": "labels.icon",
						        "stylers": [
						            {
						                "visibility": "off"
						            }
						        ]
						    },
						    {
						        "featureType": "administrative",
						        "elementType": "geometry.fill",
						        "stylers": [
						            {
						                "color": "#fefefe"
						            },
						            {
						                "lightness": 20
						            }
						        ]
						    },
						    {
						        "featureType": "administrative",
						        "elementType": "geometry.stroke",
						        "stylers": [
						            {
						                "color": "#fefefe"
						            },
						            {
						                "lightness": 17
						            },
						            {
						                "weight": 1.2
						            }
						        ]
						    },
						    {
						        "featureType": "landscape",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#f5f5f5"
						            },
						            {
						                "lightness": 20
						            }
						        ]
						    },
						    {
						        "featureType": "poi",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#f5f5f5"
						            },
						            {
						                "lightness": 21
						            }
						        ]
						    },
						    {
						        "featureType": "poi.park",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#dedede"
						            },
						            {
						                "lightness": 21
						            }
						        ]
						    },
						    {
						        "featureType": "road.highway",
						        "elementType": "geometry.fill",
						        "stylers": [
						            {
						                "color": "#ffffff"
						            },
						            {
						                "lightness": 17
						            }
						        ]
						    },
						    {
						        "featureType": "road.highway",
						        "elementType": "geometry.stroke",
						        "stylers": [
						            {
						                "color": "#ffffff"
						            },
						            {
						                "lightness": 29
						            },
						            {
						                "weight": 0.2
						            }
						        ]
						    },
						    {
						        "featureType": "road.arterial",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#ffffff"
						            },
						            {
						                "lightness": 18
						            }
						        ]
						    },
						    {
						        "featureType": "road.local",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#ffffff"
						            },
						            {
						                "lightness": 16
						            }
						        ]
						    },
						    {
						        "featureType": "transit",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#f2f2f2"
						            },
						            {
						                "lightness": 19
						            }
						        ]
						    },
						    {
						        "featureType": "water",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#e9e9e9"
						            },
						            {
						                "lightness": 17
						            }
						        ]
						    }
				]
			});
			// Create an array of alphabetical characters used to label the markers.
			// Add some markers to the map.
			// Note: The code uses the JavaScript Array.prototype.map() method to
			// create an array of markers based on a given "locations" array.
			// The map() method here has nothing to do with the Google Maps API.
			const markers = window.locations1.map((location, i) => {
				let marker = new google.maps.Marker({
				    position: location,
				    icon: "/public/images/marker.svg"
				});
			  	
			  	marker.addListener("click", function(){
			  		MicroModal.show(location.id);
			  	});

			  	return marker;
			});
			// Add a marker clusterer to manage the markers.
			new MarkerClusterer(map, markers, {
			  imagePath:
			    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
			});




			var coords = [];
			window.locations2.forEach(function(location){
				coords.push([location.lat, location.lng]);
			});

			var arr = GetCenterFromDegrees(coords);

    		const map1 = new google.maps.Map(document.getElementById("map2"), {
				zoom: 11,
				center: { lat: arr[0], lng: arr[1] },
				scaleControl: false,
				streetViewControl: false,
				mapTypeControl: false,
				fullscreenControl: false,
				styles: [
						    {
						        "featureType": "all",
						        "elementType": "labels.text.fill",
						        "stylers": [
						            {
						                "saturation": 36
						            },
						            {
						                "color": "#333333"
						            },
						            {
						                "lightness": 40
						            }
						        ]
						    },
						    {
						        "featureType": "all",
						        "elementType": "labels.text.stroke",
						        "stylers": [
						            {
						                "visibility": "on"
						            },
						            {
						                "color": "#ffffff"
						            },
						            {
						                "lightness": 16
						            }
						        ]
						    },
						    {
						        "featureType": "all",
						        "elementType": "labels.icon",
						        "stylers": [
						            {
						                "visibility": "off"
						            }
						        ]
						    },
						    {
						        "featureType": "administrative",
						        "elementType": "geometry.fill",
						        "stylers": [
						            {
						                "color": "#fefefe"
						            },
						            {
						                "lightness": 20
						            }
						        ]
						    },
						    {
						        "featureType": "administrative",
						        "elementType": "geometry.stroke",
						        "stylers": [
						            {
						                "color": "#fefefe"
						            },
						            {
						                "lightness": 17
						            },
						            {
						                "weight": 1.2
						            }
						        ]
						    },
						    {
						        "featureType": "landscape",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#f5f5f5"
						            },
						            {
						                "lightness": 20
						            }
						        ]
						    },
						    {
						        "featureType": "poi",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#f5f5f5"
						            },
						            {
						                "lightness": 21
						            }
						        ]
						    },
						    {
						        "featureType": "poi.park",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#dedede"
						            },
						            {
						                "lightness": 21
						            }
						        ]
						    },
						    {
						        "featureType": "road.highway",
						        "elementType": "geometry.fill",
						        "stylers": [
						            {
						                "color": "#ffffff"
						            },
						            {
						                "lightness": 17
						            }
						        ]
						    },
						    {
						        "featureType": "road.highway",
						        "elementType": "geometry.stroke",
						        "stylers": [
						            {
						                "color": "#ffffff"
						            },
						            {
						                "lightness": 29
						            },
						            {
						                "weight": 0.2
						            }
						        ]
						    },
						    {
						        "featureType": "road.arterial",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#ffffff"
						            },
						            {
						                "lightness": 18
						            }
						        ]
						    },
						    {
						        "featureType": "road.local",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#ffffff"
						            },
						            {
						                "lightness": 16
						            }
						        ]
						    },
						    {
						        "featureType": "transit",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#f2f2f2"
						            },
						            {
						                "lightness": 19
						            }
						        ]
						    },
						    {
						        "featureType": "water",
						        "elementType": "geometry",
						        "stylers": [
						            {
						                "color": "#e9e9e9"
						            },
						            {
						                "lightness": 17
						            }
						        ]
						    }
				]
			});
			// Create an array of alphabetical characters used to label the markers.
			// Add some markers to the map.
			// Note: The code uses the JavaScript Array.prototype.map() method to
			// create an array of markers based on a given "locations" array.
			// The map() method here has nothing to do with the Google Maps API.
			const markers1 = window.locations2.map((location, i) => {
				let marker = new google.maps.Marker({
				    position: location,
				    icon: "/public/images/marker.svg"
				});
			  	
			  	marker.addListener("click", function(){
			  		MicroModal.show(location.id);
			  	});

			  	return marker;
			});
			// Add a marker clusterer to manage the markers.
			new MarkerClusterer(map1, markers1, {
			  imagePath:
			    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
			});


    	});
    });
});